import React, { useState } from 'react';
import { storage, firestore, ref, uploadBytes, getDownloadURL, addDoc, collection } from '../../firebase'; // Ensure firebase.js is correctly imported

function AdminAddDisplay() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null); // File object
  const [visible, setVisible] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle file input
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let imageUrl = '';
      if (image) {
        // Create a reference to Firebase Storage
        const imageRef = ref(storage, `display-photos/${image.name}`);
        
        // Upload the image to Firebase Storage
        await uploadBytes(imageRef, image);
        
        // Get the download URL for the uploaded image
        imageUrl = await getDownloadURL(imageRef);
      }

      // Create display photo data
      const displayPhoto = {
        name,
        description,
        imageUrl,  // Image URL from Firebase Storage
        visible,
      };

      // Add display photo data to Firestore
      await addDoc(collection(firestore, 'display-photos'), displayPhoto);

      // Reset form
      setName('');
      setDescription('');
      setImage(null);
      setVisible(true);
      setUploading(false);
      setSuccessMessage('Display photo added successfully!');
    } catch (error) {
      console.error('Error adding display photo:', error);
      setErrorMessage('There was an error adding the display photo. Please try again.');
      setUploading(false);
    }
  };

  return (
    <div className="admin-add-display">
      <h2>Add Display Photo</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            onChange={handleFileChange}
            required
          />
        </div>
        <div>
          <label htmlFor="visible">Visible:</label>
          <input
            type="checkbox"
            id="visible"
            checked={visible}
            onChange={(e) => setVisible(e.target.checked)}
          />
        </div>
        <button type="submit" disabled={uploading}>
          {uploading ? 'Uploading...' : 'Add Photo'}
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default AdminAddDisplay;
