import React, { useState, useEffect } from "react";
import { firestore } from "../firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import PhotosDisplay from "./PhotosDisplay";
import "./Home.css";
import HomeHeader from "./HomeHeader";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import NavbarNL from "./NavbarNL";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const logoUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2FFrom%20the%20shed%20woodworks%20dark%20banner.png?alt=media&token=533c8e01-7241-42ff-bdfb-da3547ce41c0";
  
  const logoUrl2 = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fimage_2024-11-10_175651893.png?alt=media&token=e2bf365f-2774-4ce5-9612-10d95c48e618";

  const shedMidUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-mid.jpg?alt=media&token=d6d7a1ba-7f51-4153-af5e-9399ed20a83b";

  const shedLeftUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-left.jpg?alt=media&token=028f11a8-7fb4-4aae-ad4b-adaea8188adf";

  const shedRightUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-right.jpg?alt=media&token=7ca9b990-0f37-471b-be40-4013d9288b6e";


  useEffect(() => {}, []);

  return (
    <div className="home-container">
      {/* Header with Logo and Title */}
      {/* <HomeHeader /> */}
      <div className="home-header">
        <div className="header-content">
          
          <h1 className="title">Welcome to the Shed!</h1>
          <img src={logoUrl2} alt="Logo" className="logo-img" />
          {/* <img src="./image.png" alt="Logo" className="logo-img" /> */}

          {/* <h4 className="home-subtitle">Specializing in functional home decor products</h4> */}
          <h4 className="title">Specializing in functional home decor products</h4>
        
        </div>
      <img src={shedRightUrl} alt="The Shed" className="shed-photo" />
      <NavbarNL />
      {/* <Navbar /> */}
      </div>

      <br></br>



      {/* Home Screen Content */}
      <PhotosDisplay />
    </div>
  );
};

export default Home;
