// BiggerProdCard.js
import React from "react";
import PropTypes from "prop-types";
import "./BiggerProdCard.css";

const BiggerProdCard = ({ product }) => {
  const { imageUrl, description, price } = product;

  return (
    <div className="bigger-prod-card">
      <div className="bigger-image-container">
        <img src={imageUrl} alt={description} className="product-image" />
      </div>
      <div className="bigger-product-details">
        <h2 className="bigger-product-name">{description}</h2>
        <p className="bigger-product-price">${price}</p>
      </div>
    </div>
  );
};

BiggerProdCard.propTypes = {
  product: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default BiggerProdCard;
