import React from "react";
import { Link } from "react-router-dom";

function ProductCard({ product }) {
  return (
    <div className="product-card">
      <Link to={`/products/${product.id}`} state={product}>
        <img src={product.imageUrl} alt={product.description} />
        <h3>{product.description}</h3>
        <p>Price: ${product.price}</p>
      </Link>
    </div>
  );
}

export default ProductCard;
