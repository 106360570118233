import React from 'react';
import AdminNav from './AdminNav';
// import ProductManager from './ProductManager';
// import TypeManager from './TypeManager';
// import DisplayManager from './DisplayManager';

const AdminDashboard = () => {

    // useEffect(() => {
    //     console.log('AdminDashboard mounted');
    //     return () => {
    //         console.log('AdminDashboard unmounted');
    //     };
    // }, []);

    // const handleLogUser = () => {
    //     console.log('User:', user);
    //     console.log('Role:', user.role);
    // };

    return (
        <div>
            <h1>Admin Dashboard</h1>
            <AdminNav />
            {/* <ProductManager /> */}
            {/* <TypeManager /> */}
            {/* <DisplayManager /> */}
            {/* <button onClick={handleLogUser}>Log User</button> */}
        </div>
    );
};

export default AdminDashboard;
