import React, { useState } from 'react';
import { signUp, login } from './AuthService';  // AuthService with Firebase/Firestore
import { useNavigate } from 'react-router-dom';
import './AuthPage.css';

const AuthPage = ({ setIsAuthenticated, setIsAdmin, setUser }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission for both login and signup
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (isSignUp) {
        await signUp(email, password, 'not assigned');
        alert('User signed up successfully!');
      } else {
        const { user, role } = await login(email, password);
        alert('User logged in successfully!');

        // Set user authentication state
        setIsAuthenticated(true);
        // setIsAdmin(role === 'admin');  // Check if user is admin

        // Store user data in localStorage
        localStorage.setItem('user', JSON.stringify({ email: user.email, role }));
        setUser({ email: user.email, role });

        navigate('/products');
      }

      setUser(localStorage.getItem('user'));


    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>{isSignUp ? 'Sign Up' : 'Log In'}</h2>

        {error && <p className="error-message">{error}</p>}

        <form onSubmit={handleSubmit} className="auth-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input-field"
          />

          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Processing...' : isSignUp ? 'Sign Up' : 'Log In'}
          </button>
        </form>

        <button onClick={() => setIsSignUp(!isSignUp)} className="toggle-btn">
          {isSignUp ? 'Already have an account? Log In' : 'Need an account? Sign Up'}
        </button>
      </div>
    </div>
  );
};

export default AuthPage;
