import React, { useState, useEffect } from 'react';
import { firestore, collection, addDoc } from '../../firebase'; // Ensure firebase.js is correctly imported
import './AdminAddProductType.css';
import { ToastContainer, toast } from 'react-toastify';

function AdminProductTypeAdd() {
  const [name, setName] = useState('');
  const [visible, setVisible] = useState(true);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    
    // const checkLocalStorage = () => {
    //   const localStorageData = localStorage.getItem('isAuthenticated');
    //   if (localStorageData) {
    //     console.log('Local Storage Data:', localStorageData);
    //   } else {
    //     console.log('No data found in Local Storage for key: someKey');
    //   }
    //   if (!localStorageData) {
    //     window.location.href = '/';
    //   }
    // };

    // checkLocalStorage();
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setAdding(true);
  
    try {
      await addDoc(collection(firestore, 'product-types'), {
        name,
        visible,
      });
  
      setName('');
      setVisible(true);
      setAdding(false);
  
      toast.success('Product type added successfully!');
    } catch (error) {
      console.error('Error adding product type:', error);
      setAdding(false);
      toast.error('Failed to add product type.');
    }
  };
  

  return (
    <div className="admin-product-type-add">
      <ToastContainer />
      <h2>Add Product Type</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="visible">Visible:</label>
          <input
            type="checkbox"
            id="visible"
            checked={visible}
            onChange={(e) => setVisible(e.target.checked)}
          />
        </div>
        <button type="submit" disabled={adding}>
          {adding ? 'Adding...' : 'Add Product Type'}
        </button>
      </form>
    </div>
  );
}

export default AdminProductTypeAdd;
