import React, { useState } from "react";
import { firestore, collection, getDocs, deleteDoc } from "./firebase.js"; // import Firestore methods

function CopyProducts() {
  const [selectedCollection, setSelectedCollection] = useState("");

  const collections = [
    "display-photos",
    "general-inquiries",
    "prod-inquiries",
    "product-types",
    "products",
    "products-test",
    "products2",
  ];

  const handleDeleteDocuments = async () => {
    if (!selectedCollection) {
      alert("Please select a collection to clear.");
      return;
    }

    try {
      // Get reference to the selected collection
      const collectionRef = collection(firestore, selectedCollection);
      const snapshot = await getDocs(collectionRef);

      // Delete each document in the selected collection
      const deleteDocPromises = snapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Wait for all deletions
      await Promise.all(deleteDocPromises);

      alert(`${selectedCollection} documents have been deleted successfully!`);
      setSelectedCollection(""); // Reset selection after deletion
    } catch (error) {
      console.error("Error deleting documents:", error);
      alert("Failed to delete documents.");
    }
  };

  return (
    <div className="delete-documents-container">
      <h1>Delete Documents from a Collection</h1>
      <select
        value={selectedCollection}
        onChange={(e) => setSelectedCollection(e.target.value)}
      >
        <option value="">Select a collection to clear</option>
        {collections.map((collectionName) => (
          <option key={collectionName} value={collectionName}>
            {collectionName}
          </option>
        ))}
      </select>
      <button onClick={handleDeleteDocuments}>Delete Documents</button>
    </div>
  );
}

export default CopyProducts;
