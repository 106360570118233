import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where, firestore } from "../firebase";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import "./ProductList.css";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [productTypes, setProductTypes] = useState([]);
  const [isMobile, setIsMobile] = useState(false); // State to detect mobile view
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const q = query(
          collection(firestore, "products-test"),
          where("sold", "==", false)
        );
        const querySnapshot = await getDocs(q);
        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchProductTypes = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "product-types")
        );
        const types = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductTypes(types);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchProducts();
    fetchProductTypes();
  }, [navigate]);

  // Filter and sort logic
  useEffect(() => {
    let filtered = [...products];

    if (productTypeFilter) {
      filtered = filtered.filter(
        (product) => product.productType === productTypeFilter
      );
    }

    setFilteredProducts(filtered);
  }, [productTypeFilter, sortOption, products]);

  // Handle screen resizing to detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="product-list-container">
      <h1 className="product-list-title">Shop our Products</h1>

      <div className="filters-container">
        {isMobile ? (
          <select
            className="filter-dropdown"
            value={productTypeFilter}
            onChange={(e) => setProductTypeFilter(e.target.value)}
          >
            <option value="">All</option>
            {productTypes.map((type) => (
              <option key={type.id} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>
        ) : (
          <div className="filter-items">
            <button
              className={`filter-item ${
                !productTypeFilter ? "active-filter" : ""
              }`}
              onClick={() => setProductTypeFilter("")}
            >
              All
            </button>
            {productTypes.map((type) => (
              <button
                key={type.id}
                className={`filter-item ${
                  productTypeFilter === type.name ? "active-filter" : ""
                }`}
                onClick={() => setProductTypeFilter(type.name)}
              >
                {type.name}
              </button>
            ))}
          </div>
        )}
      </div>

      <ul className="product-gallery">
        {filteredProducts.length === 0 ? (
          <p className="no-products">No products available</p>
        ) : (
          filteredProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        )}
      </ul>
    </div>
  );
}

export default ProductList;
