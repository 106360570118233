// src/components/Testing.js

import React, { useState } from "react";

const Testing = ({ user, setUserRole, setNavLinkEnabled }) => {
  const [navLinkVisible, setNavLinkVisible] = useState(false);

  const handleRoleChange = (role) => {
    setUserRole(role);
  };

  const toggleNavLink = () => {
    const newVisibility = !navLinkVisible;
    setNavLinkVisible(newVisibility);
    setNavLinkEnabled(newVisibility);
  };

  return (
    <div>
      <h1>Testing Page</h1>

      <div>
        <h2>Set User Role</h2>
        <button onClick={() => handleRoleChange("admin")}>Set Admin Role</button>
        <button onClick={() => handleRoleChange("staff")}>Set Staff Role</button>
        <button onClick={() => handleRoleChange("user")}>Set User Role</button>
      </div>

      <div style={{ marginTop: "20px" }}>
        <h2>Navigation Link Control</h2>
        <button onClick={toggleNavLink}>
          {navLinkVisible ? "Hide" : "Show"} Link in Nav
        </button>
      </div>
    </div>
  );
};

export default Testing;
