import React, { useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';

function Contact() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const inquiryData = {
        email: email,
        message: message,
        responded: false, // Initially set to false
        timestamp: Timestamp.fromDate(new Date()), // Set current timestamp
      };

      // Add data to Firestore
      await addDoc(collection(firestore, 'general-inquiries'), inquiryData);
      
      toast.success('Your message has been sent successfully!');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('There was an error sending your message. Please try again later.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="contact-form-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <h2>Contact Us</h2>
      <form onSubmit={handleSendMessage}>
        <div>
          <label htmlFor="email">Your Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="message">Your Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={isSending}>
          {isSending ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
}

export default Contact;
