import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ isAuthenticated, setIsAuthenticated, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  // const imageUrl =
  //   "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Ffile.png?alt=media&token=db822618-82d1-44fb-8b06-69cdedb04f29";

  const imageUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2FFrom%20the%20shed%20woodworks%20dark%20banner.png?alt=media&token=533c8e01-7241-42ff-bdfb-da3547ce41c0";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span className="logo">
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={imageUrl} alt="From The Shed logo" className="nav-logo" />
            <h1>From the Shed Woodworks</h1>
          </Link>
        </span>
        <button className="navbar-toggle" onClick={toggleMenu}>
          <span className="hamburger-icon">&#9776;</span>
        </button>
      </div>

      {/* Auth status logging and buttons */}
      {/* <div className="auth-status-container">
        {/* <div className="auth-status">
          <p>Authentication Status: {isAuthenticated ? "Authenticated" : "Not Authenticated"}</p>
          <p>Admin Status: {isAdmin ? "Admin" : "Not Admin"}</p>
        </div>

        {/* Log Auth Status button */}
      {/* <button onClick={() => console.log(`isAuthenticated: ${isAuthenticated}, isAdmin: ${isAdmin}`)}> */}
      {/* Log Auth & Admin Status */}
      {/* </button> */}

      {/* Logout button to clear authentication */}

      {/* </div> */}

      <ul className={`navbar-list ${isOpen ? "open" : ""}`}>
        <li className="navbar-item">
          <Link to="/home" onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/products" onClick={toggleMenu}>
            Shop
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" onClick={toggleMenu}>
            Contact
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/about" onClick={toggleMenu}>
            About
          </Link>
        </li>
        {/* <li className="navbar-item">
          <button onClick={()=>{
            console.log(user)
          }}>Log User</button>
        </li> */}

        {/* button to log isAuthenticated and isAdmin status */}
        {/* <li className="navbar-item">
          <button onClick={() => console.log(`isAuthenticated: ${isAuthenticated} ${user.role}`)}>
            Log Auth & Admin Status
          </button>
        </li> */}

        {user && user.role === "staff" && (
          <div>
            <li className="navbar-item">
              <Link to="/admin" onClick={toggleMenu}>
                Admin Dashboard
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setIsAuthenticated(false);
                  localStorage.clear();
                }}
              >
                Logout
              </button>
            </li>
          </div>
        )}





      </ul>
    </nav>
  );
};

export default Navbar;
