import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase'; // Ensure this is the correct import for your Firebase config
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import './AdminProdInquiries.css';

function AdminProdInquiries() {
  const [productInquiries, setProductInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items per page

  useEffect(() => {
    const fetchProductInquiries = async () => {
      try {
        // Fetch all product inquiries
        const querySnapshot = await getDocs(collection(firestore, 'prod-inquiries'));
        const inquiries = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
          const inquiry = docSnapshot.data();
          const productRef = doc(firestore, 'products', inquiry.productId); // Assuming productId exists in the inquiry
          const productDoc = await getDoc(productRef);
          
          if (productDoc.exists()) {
            // Pre-populate the quotedPrice with the product's price
            inquiry.quotedPrice = inquiry.quotedPrice || productDoc.data().price;
          }
          
          return {
            id: docSnapshot.id,
            ...inquiry,
          };
        }));
        
        setProductInquiries(inquiries);
        setLoading(false);
        console.log(inquiries);
      } catch (error) {
        console.error('Error fetching product inquiries:', error);
      }
    };

    fetchProductInquiries();
  }, []);

  const handleInputChange = (id, field, value) => {
    setProductInquiries((prevInquiries) =>
      prevInquiries.map((inquiry) =>
        inquiry.id === id ? { ...inquiry, [field]: value } : inquiry
      )
    );
  };

  const handleSave = async (inquiryId, updatedInquiry) => {
    try {
      const inquiryRef = doc(firestore, 'prod-inquiries', inquiryId);
      await updateDoc(inquiryRef, updatedInquiry);
      console.log('Inquiry updated successfully');
    } catch (error) {
      console.error('Error updating inquiry:', error);
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(productInquiries.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentInquiries = productInquiries.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="admin-prod-inquiries">
      <h2>Product Inquiries</h2>
      {loading ? (
        <p>Loading inquiries...</p>
      ) : (
        <>
          {productInquiries.length === 0 ? (
            <p>No product inquiries available</p>
          ) : (
            <>
              <ul>
                {currentInquiries.map((inquiry) => (
                  <li key={inquiry.id}>
                    <h3>{inquiry.email}</h3>
                    <p>Product: {inquiry.productName}</p>
                    <p>Message: {inquiry.message}</p>
                    <p>
                      Responded: 
                      <select
                        value={inquiry.responded ? 'Yes' : 'No'}
                        onChange={(e) => handleInputChange(inquiry.id, 'responded', e.target.value === 'Yes')}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </p>
                    <p>
                      Quoted Price: 
                      <input
                        type="number"
                        value={inquiry.quotedPrice || ''}
                        onChange={(e) => handleInputChange(inquiry.id, 'quotedPrice', e.target.value)}
                      />
                    </p>
                    <p>
                      Sold Price: 
                      <input
                        type="number"
                        value={inquiry.soldPrice || ''}
                        onChange={(e) => handleInputChange(inquiry.id, 'soldPrice', e.target.value)}
                      />
                    </p>
                    <p>
                      Sold: 
                      <select
                        value={inquiry.sold ? 'Yes' : 'No'}
                        onChange={(e) => handleInputChange(inquiry.id, 'sold', e.target.value === 'Yes')}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </p>
                    <p>Timestamp: {inquiry.timestamp ? inquiry.timestamp.toDate().toLocaleString() : 'No timestamp available'}</p>
                    <button onClick={() => handleSave(inquiry.id, inquiry)}>Save</button>
                  </li>
                ))}
              </ul>

              {/* Pagination Controls */}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  style={{ marginRight: '10px' }}
                >
                  Previous
                </button>
                <p>Page {currentPage} of {totalPages}</p>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{ marginLeft: '10px' }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AdminProdInquiries;
