import React, { useState, useEffect, useRef } from 'react';
import { firestore, storage, ref, uploadBytes, getDownloadURL, getDocs, collection, addDoc } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminAddProduct({ isAuthenticated }) {
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [sold, setSold] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  const fileInput = useRef(null);

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'product-types'));
        setProductTypes(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        toast.error('Error fetching product types.');
        console.error('Error fetching product types:', error);
      }
    };

    fetchProductTypes();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const checkForm = () => {
    if (!description || !price || !image) {
      toast.error('Please fill in all fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    if (!checkForm()) {
      setUploading(false);
      return;
    }

    try {
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `products/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Prepare the product object without the name field
      const product = { description, price, imageUrl, sold };
      
      // Add product with autogenerated ID
      await addDoc(collection(firestore, 'products-test'), product);

      // Reset form fields
      setDescription('');
      setPrice('');
      setImage(null);
      setSold(false);
      if (fileInput.current) fileInput.current.value = '';

      setUploading(false);
      toast.success('Product uploaded successfully!');
    } catch (error) {
      console.error('Error uploading product:', error);
      setUploading(false);
      toast.error('Error uploading product. Please try again.');
    }
  };

  return (
    <div className="admin-product-type-add">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit}>
        <ToastContainer />
        {/* Form fields */}
        <div>
          <label htmlFor="productType">Product Type:</label>
          <select id="productType">
            {productTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            ref={fileInput}
            onChange={handleFileChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sold">Sold:</label>
          <input
            type="checkbox"
            id="sold"
            checked={sold}
            onChange={(e) => setSold(e.target.checked)}
          />
        </div>
        
        {/* Submit button */}
        <button type="submit" disabled={uploading}>
          {uploading ? 'Uploading...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default AdminAddProduct;
