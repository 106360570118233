import React, { useState, useEffect } from "react";
import { firestore, storage } from "../../firebase.js";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AdminProductList = () => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productTypes, setProductTypes] = useState([]); // State for product types

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(firestore, "products-test"));
      const productList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
      setLoading(false);
    };

    const fetchProductTypes = async () => {
      const querySnapshot = await getDocs(collection(firestore, "product-types"));
      const types = querySnapshot.docs.map((doc) => doc.data().name);
      setProductTypes(types);
    };

    fetchProducts();
    fetchProductTypes();
  }, []);

  const handleEdit = (productId) => {
    setEditingProduct(productId);
  };

  const handleSave = async (e, product) => {
    e.preventDefault();

    const updatedProduct = {
      description: product.description,
      price: product.price,
      sold: product.sold,
      productType: product.productType, // Include product type
    };

    if (product.imageFile) {
      const storageRef = ref(storage, `images/${product.imageFile.name}`);
      await uploadBytes(storageRef, product.imageFile);
      const imageUrl = await getDownloadURL(storageRef);
      updatedProduct.imageUrl = imageUrl;
    }

    const productRef = doc(firestore, "products-test", product.id);
    await updateDoc(productRef, updatedProduct);

    setEditingProduct(null);
  };

  const handleInputChange = (e, product, field) => {
    const value = field === "sold" ? e.target.checked : e.target.value;
    setProducts(
      products.map((p) => (p.id === product.id ? { ...p, [field]: value } : p))
    );
  };

  // Pagination logic
  const totalPages = Math.ceil(products.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentProducts = products.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Product List</h1>

      {products.length === 0 ? (
        <p></p>
      ) : (
        <>
          <ul>
            {currentProducts.map((product) => (
              <li key={product.id}>
                {editingProduct === product.id ? (
                  <form onSubmit={(e) => handleSave(e, product)}>
                    <div>
                      <label>Description:</label>
                      <textarea
                        value={product.description}
                        onChange={(e) =>
                          handleInputChange(e, product, "description")
                        }
                      />
                    </div>
                    <div>
                      <label>Price:</label>
                      <input
                        type="number"
                        value={product.price}
                        onChange={(e) => handleInputChange(e, product, "price")}
                      />
                    </div>
                    <div>
                      <label>Image:</label>
                      <input
                        type="file"
                        onChange={(e) =>
                          handleInputChange(
                            { target: { value: e.target.files[0] } },
                            product,
                            "imageFile"
                          )
                        }
                      />
                    </div>
                    <div>
                      <label>Sold:</label>
                      <input
                        type="checkbox"
                        checked={product.sold}
                        onChange={(e) => handleInputChange(e, product, "sold")}
                      />
                    </div>
                    <div>
                      <label>Product Type:</label>
                      <select
                        value={product.productType || ""}
                        onChange={(e) =>
                          handleInputChange(e, product, "productType")
                        }
                      >
                        <option value="" disabled>
                          Select a product type
                        </option>
                        {productTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button type="submit">Save</button>
                    <button
                      type="button"
                      onClick={() => setEditingProduct(null)}
                    >
                      Cancel
                    </button>
                  </form>
                ) : (
                  <div>
                    <p>ID: {product.id}</p>
                    <p>Description: {product.description}</p>
                    <p>Price: ${product.price}</p>
                    <p>Type: {product.productType || "N/A"}</p>
                    <p>Status: {product.sold ? "Sold" : "Available"}</p>
                    {product.imageUrl && (
                      <img
                        src={product.imageUrl}
                        alt={`Product ${product.id}`}
                        width="100"
                      />
                    )}
                    <button onClick={() => handleEdit(product.id)}>Edit</button>
                  </div>
                )}
              </li>
            ))}
          </ul>

          {/* Pagination Controls */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              style={{ marginRight: "10px" }}
            >
              Previous
            </button>
            <p>
              Page {currentPage} of {totalPages}
            </p>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ marginLeft: "10px" }}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminProductList;
