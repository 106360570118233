// src/firebase.js
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, setDoc, collection, getDocs, getDoc, query, where, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBh_FduRpzct6jtHHzk9urETIK62Br-LIE",
  authDomain: "fromtheshed-c9845.firebaseapp.com",
  projectId: "fromtheshed-c9845",
  storageBucket: "fromtheshed-c9845.appspot.com",
  messagingSenderId: "243625197857",
  appId: "1:243625197857:web:5d21585a010bbbf8f47e60",
  measurementId: "G-YWX54ZKMRL"
};

// const firebaseConfig = {
//     apiKey: "YOUR_API_KEY",
//     authDomain: "YOUR_AUTH_DOMAIN",
//     projectId: "YOUR_PROJECT_ID",
//     storageBucket: "YOUR_STORAGE_BUCKET",          
//     messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//     appId: "YOUR_APP_ID"
//   };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { storage, firestore, auth, ref, uploadBytes, getDownloadURL, doc, setDoc, addDoc, collection, getDocs, getDoc, query, where, updateDoc, deleteDoc };