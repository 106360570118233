import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs, doc, updateDoc, deleteDoc } from '../../firebase'; // Ensure firebase.js is correctly imported

function AdminProductTypeList() {
  const [productTypes, setProductTypes] = useState([]);
  const [editedProductType, setEditedProductType] = useState(null);
  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    
    // const checkLocalStorage = () => {
    //   const localStorageData = localStorage.getItem('isAuthenticated');
    //   if (!localStorageData) {
    //     console.log('No data found in Local Storage for key: someKey');
    //     window.location.href = '/';
    //   } else {
    //     console.log('Local Storage Data:', localStorageData);
    //   }
    // };

    // checkLocalStorage();

    const fetchProductTypes = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(firestore, 'product-types'));
        const types = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProductTypes(types);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product types:', error);
        setLoading(false);
      }
    };

    fetchProductTypes();
  }, []);

  const handleEdit = (productType) => {
    setEditedProductType(productType);
    setNewName(productType.name);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updates = { name: newName };
    if (!editedProductType) return;

    try {
      const typeRef = doc(firestore, 'product-types', editedProductType.id);
      await updateDoc(typeRef, updates);
      setProductTypes(productTypes.map(pt => pt.id === editedProductType.id ? { ...pt, ...updates } : pt));
      setEditedProductType(null);
      setNewName('');
    } catch (error) {
      console.error('Error updating product type:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const typeRef = doc(firestore, 'product-types', id);
      await deleteDoc(typeRef);
      setProductTypes(productTypes.filter(pt => pt.id !== id));
    } catch (error) {
      console.error('Error deleting product type:', error);
    }
  };

  const toggleVisibility = async (productType) => {
    try {
      const typeRef = doc(firestore, 'product-types', productType.id);
      const newVisibility = !productType.visible;
      await updateDoc(typeRef, { visible: newVisibility });
      setProductTypes(productTypes.map(pt => pt.id === productType.id ? { ...pt, visible: newVisibility } : pt));
    } catch (error) {
      console.error('Error toggling visibility:', error);
    }
  };

  return (
    <div className="admin-product-type-list">
      <h2>Product Types</h2>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <ul>
            {productTypes.length === 0 ? (
              <p>No product types available</p>
            ) : (
              productTypes.map(productType => (
                <li key={productType.id}>
                  <span>{productType.name}</span>
                  <button onClick={() => handleEdit(productType)}>Edit</button>
                  <button onClick={() => handleDelete(productType.id)}>Delete</button>
                  <button onClick={() => toggleVisibility(productType)}>
                    {productType.visible ? 'Hide' : 'Show'}
                  </button>
                </li>
              ))
            )}
          </ul>

          {editedProductType && (
            <div className="edit-product-type-form">
              <h3>Edit Product Type</h3>
              <form onSubmit={handleUpdate}>
                <div>
                  <label htmlFor="new-name">New Name:</label>
                  <input
                    type="text"
                    id="new-name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Update</button>
                <button type="button" onClick={() => setEditedProductType(null)}>Cancel</button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AdminProductTypeList;
