import React from 'react';
import './Fonts.css';

const fonts = [
  { name: 'Roboto', url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap' },
  { name: 'Lobster', url: 'https://fonts.googleapis.com/css2?family=Lobster&display=swap' },
  { name: 'Open Sans', url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap' },
  { name: 'Montserrat', url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap' },
  { name: 'Poppins', url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap' },
  { name: 'Raleway', url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap' },
  { name: 'Oswald', url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap' },
  { name: 'Merriweather', url: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap' },
];

fonts.forEach(font => {
  const link = document.createElement('link');
  link.href = font.url;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
});

const Fonts = () => {
  return (
    <div className="fonts-container">
      <h1>Google Fonts Showcase</h1>
      {fonts.map(font => (
        <div key={font.name} className="font-sample" style={{ fontFamily: font.name }}>
          <p>{font.name} - The quick brown fox jumps over the lazy dog.</p>
        </div>
      ))}
    </div>
  );
};

export default Fonts;
