import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, firestore } from '../../firebase';  // Firestore added
import { doc, setDoc, getDoc } from 'firebase/firestore';  // Firestore methods

// Sign Up
export const signUp = async (email, password) => {
  try {
    // Create user with Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create user document in Firestore with role 'staff'
    await setDoc(doc(firestore, 'users', user.uid), {
      email: user.email,
      role: 'not assigned',  
    });

    return user;
  } catch (error) {
    throw error;
  }
};

// Log In
export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch user role from Firestore
    const userDoc = await getDoc(doc(firestore, 'users', user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return { user, role: userData.role };  // Return user and role
    } else {
      throw new Error('No user data found');
    }
  } catch (error) {
    throw error;
  }
};

// Log Out
export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};
