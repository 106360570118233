import React, { useState, useEffect } from "react";
import { firestore, storage } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ProductManager = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [formState, setFormState] = useState({
    description: "",
    price: "",
    imageFile: null,
    sold: false,
    productType: "",
    imageUrl: "",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(firestore, "products-test"));
      const productList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  const resetForm = () => {
    setFormState({
      description: "",
      price: "",
      imageFile: null,
      sold: false,
      productType: "",
      imageUrl: "",
    });
    setSelectedProductId("");
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleImageChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      imageFile: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = formState.imageUrl;
      if (formState.imageFile) {
        const storageRef = ref(storage, `images/${formState.imageFile.name}`);
        await uploadBytes(storageRef, formState.imageFile);
        imageUrl = await getDownloadURL(storageRef);
      }

      const productData = {
        description: formState.description,
        price: parseFloat(formState.price),
        imageUrl,
        sold: formState.sold,
        productType: formState.productType,
      };

      if (selectedProductId) {
        // Update existing product
        const productRef = doc(firestore, "products-test", selectedProductId);
        await updateDoc(productRef, productData);
      } else {
        // Add new product
        await addDoc(collection(firestore, "products-test"), productData);
      }

      resetForm();
      const querySnapshot = await getDocs(collection(firestore, "products-test"));
      const updatedProducts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(updatedProducts);
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  const handleProductSelect = (e) => {
    const productId = e.target.value;
    setSelectedProductId(productId);

    if (productId) {
      const product = products.find((p) => p.id === productId);
      setFormState({
        description: product.description || "",
        price: product.price || "",
        imageFile: null,
        sold: product.sold || false,
        productType: product.productType || "",
        imageUrl: product.imageUrl || "",
      });
    } else {
      resetForm();
    }
  };

  const selectedProduct = products.find((product) => product.id === selectedProductId);

  return (
    <div style={{ display: "flex", gap: "30px", padding: "20px", justifyContent: "space-between", width: "100%" }}>
      {/* Left: Product List */}
      <div style={{ flex: 1, padding: "10px", border: "1px solid #ddd", borderRadius: "8px", minWidth: "300px" }}>
        <h2>Product List</h2>
        <select
          value={selectedProductId}
          onChange={handleProductSelect}
          style={{
            width: "100%",
            height: "200px",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ddd",
            fontSize: "1rem",
          }}
          size={10}
        >
          <option value="">-- Add New Product --</option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {`ID: ${product.id} | Description: ${product.description || "N/A"} | Price: $${product.price || 0}`}
            </option>
          ))}
        </select>
      </div>

      {/* Right: Form */}
      <div style={{ flex: 2, padding: "10px", border: "1px solid #ddd", borderRadius: "8px", minWidth: "400px" }}>
        <h2>{selectedProductId ? "Edit Product" : "Add New Product"}</h2>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "20px" }}>
            <label>Description:</label>
            <textarea
              name="description"
              value={formState.description}
              onChange={handleFormChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "1rem",
                minHeight: "100px",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label>Price:</label>
            <input
              type="number"
              name="price"
              value={formState.price}
              onChange={handleFormChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "1rem",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label>Image:</label>
            <input
              type="file"
              onChange={handleImageChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            />
            {selectedProduct && selectedProduct.imageUrl && !formState.imageFile && (
              <div style={{ marginTop: "10px" }}>
                <p>Current Image:</p>
                <img
                  src={selectedProduct.imageUrl}
                  alt={`Product ${selectedProduct.id}`}
                  style={{ maxWidth: "100px", height: "auto" }}
                />
              </div>
            )}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label>Sold:</label>
            <input
              type="checkbox"
              name="sold"
              checked={formState.sold}
              onChange={handleFormChange}
              style={{ marginLeft: "10px" }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label>Product Type:</label>
            <input
              type="text"
              name="productType"
              value={formState.productType}
              onChange={handleFormChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "1rem",
              }}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" style={{ padding: "10px 15px", backgroundColor: "#007bff", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>
              {selectedProductId ? "Update Product" : "Add Product"}
            </button>
            <button type="button" onClick={resetForm} style={{ padding: "10px 15px", backgroundColor: "#ccc", border: "none", borderRadius: "5px", cursor: "pointer" }}>
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductManager;
