import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PhotosDisplay.css";

const PhotosDisplay = () => {
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchVisiblePhotos = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "display-photos")
        );
        const photosArray = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            original: doc.data().imageUrl,
            thumbnail: doc.data().imageUrl,
            name: doc.data().name,
            visible: doc.data().visible,
          }))
          .filter((photo) => photo.visible); // Only include visible photos

        setGalleryItems(photosArray);
      } catch (error) {
        console.error("Error fetching photos: ", error);
      }
    };

    fetchVisiblePhotos();
  }, []);

  return galleryItems.length > 0 ? (
    <div className="gallery-p-container">
      <h2>View our work</h2>
      <div className="gallery-container">
        <ImageGallery
          items={galleryItems}
          showThumbnails={true}
          showPlayButton={true}
          showFullscreenButton={true}
          renderItem={(item) => (
            <div style={{ position: "relative" }}>
              <img
                src={item.original}
                alt={item.name}
                className="image-slide"
                style={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              />
              {item.name && <p style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold" }}>{item.name}</p>}
            </div>
          )}
        />
      </div>
    </div>
  ) : (
    <div className="no-photos-container" style={{ textAlign: "center", padding: "20px" }}>
      {/* <p style={{ fontSize: "18px", color: "#555" }}>No photos to display.</p> */}
      <a href="/products" style={{ fontSize: "18px", color: "#007bff", textDecoration: "none" }}>Check out our products</a>
    </div>
  );
}


export default PhotosDisplay;
