import React, { useState, useEffect } from "react";
import { addDoc, collection, Timestamp, doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProductForm.css";

function ProductForm({ productId }) {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [product, setProduct] = useState(null);  // State to store product info
  const [quotedPrice, setQuotedPrice] = useState(null);  // State for the quoted price

  // Fetch product details from Firestore
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRef = doc(firestore, "products-test", productId);
        const productDoc = await getDoc(productRef);
        
        if (productDoc.exists()) {
          const productData = productDoc.data();
          setProduct(productData);
          setQuotedPrice(productData.price);  // Set quoted price from product price
        } else {
          console.log("Product not found.");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  // Handle form submission
  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      // Create inquiry data with the quoted price
      const inquiryData = {
        email: email,
        productId: productId,
        message: message,
        responded: false,
        quotedPrice: quotedPrice,  // Set the quoted price
        soldPrice: null,
        sold: false,
        timestamp: Timestamp.fromDate(new Date()),
      };

      // Add inquiry to Firestore
      await addDoc(collection(firestore, "prod-inquiries"), inquiryData);

      // Send email notification (pseudo-function for email logic)
      await sendEmailNotification(email, productId, message);

      toast.success("Message sent successfully! We will get back to you soon.");
      setMessage("");
      setEmail("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("There was an error sending your message. Please try again later.");
    } finally {
      setIsSending(false);  // Re-enable form submission
    }
  };

  const sendEmailNotification = async (email, productId, message) => {
    try {
      // Placeholder for sending email logic (e.g., via Mailgun or SendGrid)
      console.log(`Sending email to admin: ${email}, Product ID: ${productId}, Message: ${message}`);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  if (!product) {
    return <p>Loading product details...</p>;  // Loading state while fetching product data
  }

  return (
    <div className="contact-admin">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <h2>Interested? Send us a message!</h2>
      <form onSubmit={handleSendMessage}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <textarea
            className="message-box"
            placeholder="Write your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          {/* <p>Quoted Price: ${quotedPrice}</p> */}
        </div>
        <button type="submit" className="submit-button" disabled={isSending}>
          {isSending ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
}

export default ProductForm;
